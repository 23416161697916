<template>
    <div>
        <div class="interface-container m-2">
            <div class="interface">
                <div class="d-flex justify-content-between align-items-end">
                    <!--<button v-if="zoom_scale !== false" id="export" type="button" :class="['btn me-1', (input.zoom)? 'btn-primary' : 'btn-secondary']" @click="input.zoom = !input.zoom">Zoom</button>-->
                    <button id="export" type="button" :class="[`btn ${show.marker_interface? 'btn-primary' : 'btn-secondary'} position-relative me-4`]" @click="show.marker_interface = !show.marker_interface">
                        Marker
                        <span class="badge text-bg-primary">{{markers.length}}</span>
                    </button>
                    <button id="export" type="button" class="btn btn-primary" @click="$refs.map.reload()">Reload</button>
                </div>
                <div v-if="show.marker_interface" class="mt-4">
                    <div class="position-relative">
                        <input class="form-control" list="searchOptions" placeholder="Suche..." v-model="search.input">
                        <div v-if="search.results.length > 0" class="search-results">
                            <div @click="addMarker(result)" v-for="(result, index) of search.results" :key="index" class="search-result">
                                {{result.display_name}}
                            </div>
                        </div>
                    </div>
                    <div class="marker-container mt-2">
                        <div v-for="(marker, index) of markers" :key="index" class="marker-item">
                            <input type="text" class="form-control form-control-sm me-2" style="max-width:40px;" readonly :value="marker.number">
                            <div>{{marker.item.display_name}}</div>
                            <i @click="$refs.map.removeMarker(marker)" class="bi bi-x-lg"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="zoom-info">
                <div class="zoom-info-title">Zoom</div>
                <div class="zoom-info-value">{{input.zoom}}</div>
            </div>
        </div>

        <transition name="fade-in">
            <div v-if="show_render" class="render-container">
                <div class="render">
                    <div class="row align-items-end">
                        <div class="col-3">
                            <div class="form-group">
                                <label for="map-width">Zoom</label>
                                <select class="form-control" v-model="input.render.zoom">
                                    <option v-for="zoom of 20" :key="zoom" :value="zoom">{{zoom}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="map-width">Format</label>
                                <select class="form-control" v-model="input.render.format">
                                    <option value="pdf">PDF</option>
                                    <option value="svg">SVG</option>
                                    <option value="png">PNG</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex justify-content-end align-items-center">
                                <button id="export" type="button" class="btn btn-primary me-1" @click="$refs.map.renderFile(input.render.zoom, input.render.format)">Rendern</button>
                                <button id="export" type="button" class="btn btn-secondary" @click="$refs.map.clearDragRender()">Abbrechen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div v-if="init_map && $refs.map.dragRender.rendering" class="loading-overlay">
            <div>Map wird gerendert...</div>
        </div>

        <div id="map-container" class="map-container">
            <Map v-if="init" ref="map"/>
            <div class="map-overlay">
                <div class="map-outlines"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Map from "@/components/Map";
export default {
    name: 'Home',
    components: {Map},
    data: () => ({
        init: false,
        init_map: false,
        search: {
            input: "",
            results: []
        },
        input: {
            zoom: 0,
            render: {
                zoom: 4,
                format: 'pdf'
            }
        },
        show: {
            marker_interface: false
        },
        task: {
            search: null,
            map_init: null
        }
    }),
    computed: {
        markers: function(){
            return (this.init && this.init_map)? this.$refs.map.markers : [];
        },
        show_render: function(){
            return this.init_map && !this.$refs.map.dragRender.drag && this.$refs.map.dragRender.coordinates.start && this.$refs.map.dragRender.coordinates.end;
        }
    },
    watch: {
        'init': function(){
            this.updateZoom();
        },
        '$route.params': function(){
            this.updateZoom();
        },
        'search.input': function(){
            this.queueSearch();
        },
        show_render: function(){
            this.input.render.zoom = this.input.zoom;
        }
    },
    methods: {
        updateZoom(){
            const data = this.$route.params.data;
            const zoom = data.split(',')[0];
            this.input.zoom = parseInt(zoom);
        },

        queueSearch(){
            if(this.task.search){
                clearTimeout(this.task.search);
            }

            if(this.search.results.length > 0){
                this.search.results = [];
            }

            if(this.search.input.length > 2){
                this.task.search = setTimeout(() => this.requestSearch(), 1000);
            }
        },
        async requestSearch(){
            this.task.search = null;

            const r = await axios.get(`https://nominatim.openstreetmap.org/search`, { params: {format: "jsonv2", q: this.search.input} });
            if(r && r.data){
                this.search.results = r.data;
            }
        },
        addMarker(searchResult){
            this.search.input = "";

            this.$refs.map.addMarker(searchResult);
        }
    },
    mounted() {
        this.task.map_init = setInterval(() => {
            if(this.$refs.map){
                this.init_map = true;
                clearInterval(this.task.map_init);
            }
        }, 20);

        setTimeout(() => {
            this.init = true;
        });
    }
}
</script>

<style>
body{
    overflow: hidden;
}

#map{
    position: relative;
}

.loading-overlay{
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,0.16);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
}

.render-container{
    position: absolute;
    z-index: 2;
    bottom: 60px;
    padding: 0 12px;

    width: min(100%, 600px);
    left: calc(50% - (min(100%, 600px) / 2));

    display: flex;
}
.render-container .render{
    position: relative;
    width: 100%;
    padding: 12px;

    border: 2px solid rgba(255,255,255,0.5);
    background-color: rgba(255,255,255,0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 16px;
}

.interface-container{
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    padding: 12px;

    display: flex;
}
.interface-container .interface{
    padding: 8px 16px;
    border: 2px solid rgba(255,255,255,0.5);
    background-color: rgba(255,255,255,0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 8px;
    min-width: 360px;
}
.interface-container .interface input.form-control[type="number"]{
    max-width: 150px;
}
.interface-container .zoom-info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid rgba(255,255,255,0.5);
    background-color: rgba(255,255,255,0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    text-align: center;
    line-height: 0.8;
    margin-left: 10px;
}
.interface-container .zoom-info .zoom-info-title{
    font-size: 12px;
}
.interface-container .zoom-info .zoom-info-value{
    font-size: 32px;
    font-weight: bold;
}
.map-container{
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #a0a0a0;
}

.seperation{
    display: flex;
    align-items: center;
}
.seperation .icon{
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: var(--bs-blue);
}
.seperation .icon:hover{
    cursor: pointer;
    background-color: rgba(255,255,255,0.5);
}
.seperation .line{
    flex: 1;
    height: 2px;
    background-color: rgba(255,255,255,0.5);
}

.search-results{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 320px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 8px rgba(0,0,0,0.16);
    overflow-y: scroll;
    overflow-x: hidden;
}
.search-results .search-result{
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.2;
}
.search-results .search-result:hover{
    cursor: pointer;
    background-color: rgba(0,0,0,0.04);
}

.marker-container{
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
    height: 240px;
    overflow-y: auto;
}
.marker-container .marker-item{
    display: flex;
    align-items: center;

    padding: 8px 16px;
    max-width: 460px;

    font-size: 14px;
    line-height: 1.2;
}
.marker-container .marker-item i:hover{
    cursor: pointer;
}

.map-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 12px;
    pointer-events: none;
}
.map-overlay .map-outlines{
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(255,255,255,0.75);
    border-radius: 16px;
}
.map-overlay .map-outlines::after,
.map-overlay .map-outlines::before{
    content: "";
    position: absolute;
    z-index: 1;
    background-color: rgba(255,255,255,0.75);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
.map-overlay .map-outlines::after{
    left: calc(50% - 1px);
    top: 0;
    height: 100%;
    width: 2px;
}
.map-overlay .map-outlines::before{
    left: 0;
    top: calc(50% - 1px);
    height: 2px;
    width: 100%;
}

.fade-in-enter-active,
.fade-in-leave-active {
    transition: 0.3s ease;
}
.fade-in-enter-from,
.fade-in-leave-to {
    opacity: 0;
    transform: translateY(300px);
}
</style>
