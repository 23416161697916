<template>
    <div id="map" :style="{width: `100%`, height: `100%`}" @mousedown="onMouseDown" @mouseup="onMouseUp"></div>
</template>

<script>
import {Feature, Map, View} from 'ol';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {XYZ, Vector as VectorSource} from 'ol/source';
import {Point, Polygon} from 'ol/geom';
import {useGeographic} from 'ol/proj';
import {Fill, Stroke, Style, Text, Circle as CircleStyle} from 'ol/style';
import axios from "axios";
import fileDownload from "js-file-download";

export default {
    name: "Map",
    data: () => ({
        map: null,
        markers: [],
        pointer: [0, 0],

        dragRender: {
            rendering: false,
            drag: false,
            layer: null,
            coordinates: {
                start: null,
                end: null
            }
        }
    }),
    watch: {
        width: function(value){
            if(this.map){
                this.map.setSize([value, this.map.getSize()[1]]);
            }
        },
        height: function(value){
            if(this.map){
                this.map.setSize([this.map.getSize()[0], value]);
            }
        },
        'dragRender.drag': function(dragging){
            if(this.map){
                const dragPan = this.map.getInteractions().item(2);
                if(dragPan) dragPan.setActive(!dragging);
            }
        },
    },
    methods: {
        updateUrl(){
            const center = this.map.getView().getCenter();
            const zoom = this.map.getView().getZoom();

            this.$router.push({query: this.$route.query, params: {data: [zoom, center[0], center[1]].join(',')}});
        },
        reload(){
            this.destroy();
            this.construct();
        },

        async renderFile(zoom, format){
            this.dragRender.rendering = true;
            const r = await axios.post(`http://printmap.simadesign.de:3000/render/${format}`, {
                zoom,
                coordinates: this.getDragRenderCoordinates()
            }).catch(error => console.log(error));
            this.dragRender.rendering = false;

            if(r.data && r.data.output){
                const fileName = r.data.output;
                const fileUrl = `http://printmap.simadesign.de/render/${fileName}`;

                console.log(fileName, fileUrl);
                axios.get(fileUrl, { responseType: 'blob' }).then(r => fileDownload(r.data, fileName));
            }
        },
        clearDragRender(){
            this.dragRender.coordinates.start = null;
            this.dragRender.coordinates.end = null;
            if(this.dragRender.layer){
                this.map.getLayers().remove(this.dragRender.layer);
                this.dragRender.layer = null;
            }
        },
        getDragRenderCoordinates(){
            let left = 0;
            let top = 0;
            let right = 0;
            let bottom = 0;

            const start = this.dragRender.coordinates.start;
            const end = this.dragRender.coordinates.end;
            if(start && end){
                left = (end[0] > start[0])? start[0] : end[0];
                right = (end[0] > start[0])? end[0] : start[0];
                bottom = (end[1] > start[1])? start[1] : end[1];
                top = (end[1] > start[1])? end[1] : start[1];
            }

            return {left, top, right, bottom};
        },
        updateDragRenderMarker(){
            if(this.dragRender.drag){
                if(this.dragRender.coordinates.start && this.dragRender.coordinates.end){

                    console.log(this.getDragRenderCoordinates());
                    const {left, top, right, bottom} = this.getDragRenderCoordinates();
                    const polygonCoordinates = [
                        [
                            [left, bottom],
                            [left, top],
                            [right, top],
                            [right, bottom]
                        ]
                    ];

                    if(!this.dragRender.layer){
                        this.dragRender.layer = new VectorLayer({
                            source: new VectorSource({
                                features: [new Feature(new Polygon(polygonCoordinates))],
                            }),
                            style: new Style({
                                stroke: new Stroke({
                                    color: 'rgb(100, 100, 250)',
                                    width: 3,
                                }),
                                fill: new Fill({
                                    color: 'rgba(100, 100, 250, 0.16)',
                                }),
                            })
                        });
                        this.map.addLayer(this.dragRender.layer);
                    } else {
                        const layer = this.dragRender.layer;
                        const polygonFeature = layer.getSource().getFeatures()[0];
                        const polygon = polygonFeature.getGeometry();
                        polygon.setCoordinates(polygonCoordinates);
                    }

                    console.log(this.map.getLayers());

                }
            }
        },
        onMouseDown(event){
            if(event.ctrlKey){
                this.dragRender.drag = true;
            }

            if(this.dragRender.drag){
                this.dragRender.coordinates.start = null;
                this.dragRender.coordinates.end = null;
            }
        },
        onMouseUp(){
            if(this.dragRender.drag){
                if(this.dragRender.coordinates.start && this.dragRender.coordinates.end){
                    this.updateDragRenderMarker();
                }

                this.dragRender.drag = false;
            }
        },

        addAllMarkers(){
            for(const marker of this.markers){
                this.map.addLayer(marker.layer);
            }
        },
        addMarker(item){
            const coordinates = [item.lon, item.lat];

            const number = this.markers.length + 1;
            const layer = new VectorLayer({
                source: new VectorSource({
                    features: [new Feature(new Point(coordinates))],
                }),
                style: new Style({
                    image: new CircleStyle({
                        radius: 24,
                        fill: new Fill({color: '#d0535c'}),
                    }),
                    text: new Text({
                        text: `${number}`,
                        font: '28px sans-serif',
                        fill: new Fill({
                            color: '#ffffff',
                        })
                    })
                })
            });

            this.map.addLayer(layer);

            console.log(JSON.stringify(coordinates));

            this.markers.push({
                coordinates,
                number,
                layer,
                item
            });
        },
        removeMarker(marker){
            const index = this.markers.indexOf(marker);
            if(index !== -1){
                this.map.getLayers().remove(marker.layer);
                this.markers.splice(index, 1);
            }
        },
        construct(){
            useGeographic();

            const data = (this.$route.params.data)? this.$route.params.data.split(',') : false;
            const viewData = (data)? {center: [Number.parseFloat(data[1]), Number.parseFloat(data[2])], zoom: Number.parseFloat(data[0])} : {center: [0, 0], zoom: 2};

            viewData.constrainResolution = true;

            this.map = new Map({
                target: 'map',
                controls: [],
                layers: [
                    new TileLayer({
                        source: new XYZ({
                            url: `http://printmap.simadesign.de/hot/{z}/{x}/{y}.png`,
                            crossOrigin: 'Anonymous',
                        })
                    })
                ],
                view: new View(viewData),
            });
            this.map.on('moveend', this.updateUrl);
            this.map.on('pointermove', this.onPointerMove);
            this.map.on('pointerdrag', this.onPointerDrag);

            this.addAllMarkers();
        },
        destroy(unmounting = false){
            this.map.un('moveend', this.updateUrl);
            this.map = null;
            if(!unmounting){
                document.getElementById('map').innerHTML = '';
            }
        },
        onPointerMove(event){
            this.pointer = event.coordinate;
        },
        onPointerDrag(event){
            if(this.dragRender.drag){
                if(!this.dragRender.coordinates.start){
                    this.dragRender.coordinates.start = event.coordinate;
                }
                this.dragRender.coordinates.end = event.coordinate;

                this.updateDragRenderMarker();
            }
        },
    },
    mounted() {
        this.construct();
    },
    beforeUnmount() {
        this.destroy(true);
    }
}
</script>

<style>
#map{
    width: 100%;
    height: 100%;
    margin: auto;
}
</style>